<template>
	<div class="login">
		<div class="login-LR">
			<div class="login-L" data-aos="slide-right" data-aos-delay="0">
				<div class="login-L1">
					<img src="../assets/img/logo3.png">
				</div>
				<div class="login-L2">
					宝智达冷链-云平台管理系统v3.0
				</div>
				<div class="login-L3">
					Bozhida Cold Chain - Cloud platform management system V3.0
				</div>
			</div>
			<div class="login-R">
				<div class="login-Rcont">
					<div class="login-R1" data-aos="slide-left" data-aos-delay="0">
						登录/Login
					</div>
					<div class="login-R2" data-aos="slide-left" data-aos-delay="50">
						<input type="text" placeholder="OA账号" v-model="from.bzd_username" @keyup.enter="Login">
					</div>
					<div class="login-R3" data-aos="slide-left" data-aos-delay="100">
						<input type="password" placeholder="OA密码" v-model="from.bzd_password" @keyup.enter="Login">
					</div>
					<div class="login-R4" @click="Login" data-aos="slide-left" data-aos-delay="150">
						<el-button type="warning" round style="width: 100%;" size="small" :loading="loading">
							{{loginTxt}}
						</el-button>
					</div>
                    
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		login
	} from '../api/login.js'
	import {mapGetters} from "vuex"
	export default {
		computed:mapGetters(["showFirm"]),
		name: "Home",
		data() {
			return {
				from: {
					bzd_username: '',
					bzd_password: ''
				},
				loginTxt: '登录',
				loading: false
			}
		},
        
		mounted() {
			this.$store.dispatch('SETPANY', {T_name:'',bl:false,plan:''}) //显示公司  登录页不显示
			// this.$store.commit('SETNAVS', []) 
			// ////console.log('显示123',this.showFirm)
			this.from.bzd_username = this.$store.state.userInfo.username
			this.from.bzd_password = this.$store.state.userInfo.password
		},
		methods: {
            
			async Login() {
				var _this = this
				_this.loading = true
				if (this.from.bzd_username === '') {
					_this.$message.error('登录账号不能为空哦')
					_this.loading = false
					return
				} else if (this.from.bzd_password === '') {
					_this.$message.error('登录密码不能为空哦')
					_this.loading = false
					return
				} else {
					_this.loginTxt = '登陆中'
					var obj = {
						bzd_username: _this.from.bzd_username,
						bzd_password: _this.$md5(_this.from.bzd_password)
					}
					let code = await this.Getlogin(obj)
					if (code.data.Code === 200) {
						_this.$store.commit('setuserInfo', {
							username: _this.from.bzd_username,
							password: _this.from.bzd_password,
							token: code.data.Data
						})
						setTimeout(function() {
							_this.loading = false
							_this.loginTxt = '登录'
							_this.$router.replace('/index')
						}, 1000)
					} else {
						_this.loading = false
						_this.loginTxt = '登录'
					}
				}
			},
			Getlogin(e) {
				return new Promise(resolve => {
					login(e).then(function(res) {
						resolve(res);
					})
				});
			},
		}
	};
</script>
<style lang="scss">
	.login {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		user-select: none;

		@media screen and (min-width: 800px) {
			.login-LR {
				min-width: 700px;
				height: 450px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				overflow: hidden;

				.login-L {
					flex: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					color: #fff;

					.login-L1 {
						width: 70px;
						height: 70px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.login-L2 {
						font-size: 16px;
						margin-top: 15px;
						font-weight: 100;
					}

					.login-L3 {
						margin-top: 2px;
						font-size: 12px;
						font-weight: 0;
						color: #ccc;
					}
				}

				.login-R {
					flex: 1;
					display: flex;
					flex-direction: column;
					border-left: .5px solid #474856;

					.login-Rcont {
						width: 70%;
						margin-left: 50px;

						.login-R1 {
							font-size: 22px;
							font-weight: 100;
						}

						.login-R2 {
							margin-top: 40px;
							border-bottom: 1px solid #474856;
							height: 30px;

							input {
								outline: none;
								background: none;
								border: none;
								color: #fff;
								width: 100%;
								height: 100%;
								padding-left: 10px;
							}
						}

						.login-R3 {
							margin-top: 40px;
							border-bottom: 1px solid #474856;
							height: 30px;

							input {
								outline: none;
								background: none;
								border: none;
								color: #fff;
								width: 100%;
								height: 100%;
								padding-left: 10px;
							}
						}

						.login-R4 {
							margin-top: 40px;
							width: 100%;
						}
					}
				}
			}
		}

		@media screen and (max-width: 800px) {
			.login-LR {
				width: 100%;

				.login-L {
					display: none;
				}

				.login-R {
					display: flex;
					justify-content: center;

					.login-Rcont {
						width: 80%;

						.login-R1 {
							font-size: 22px;
							font-weight: 100;
						}

						.login-R2 {
							margin-top: 40px;
							border-bottom: 1px solid #474856;

							input {
								outline: none;
								background: none;
								border: none;
								color: #fff;
							}
						}

						.login-R3 {
							margin-top: 40px;
							border-bottom: 1px solid #474856;
							height: 30px;

							input {
								outline: none;
								background: none;
								border: none;
								color: #fff;
								width: 100%;
								height: 100%;
							}
						}

						.login-R4 {
							margin-top: 40px;
							width: 100%;
						}
					}
				}
			}
		}
	}
</style>
